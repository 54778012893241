.main-container{
    padding-top: 40px;
}
@media only screen and (max-width: 1178px) {
    .main-container{
        padding-top: 80px;
    }
}
@media only screen and (max-width: 874px) {
    .main-container{
        padding-top: 120px;
    }
}

body {
	padding-top: 0;
	padding-bottom: 0px;
    overflow-x: hidden;
    overflow-y: auto !important;
}

.login-title{
	margin-top :50px;
}

.login-h2{
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: #FFFFFF
}

.login-center{
	min-height: 350px;
	& div.language-change {
		text-align: right;

		& a {
			padding: 5px;
			margin-left: 2px;
			margin-right: 2px;
		}
	}
}

.login-img{
	width: 90%;
	vertical-align: middle;
}

.login-imgff{
	height: 50px;
	vertical-align: middle;
}

.col-center-block {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

hr{
	margin-top: 5px;
	margin-bottom :5px;
	border-color: #428bca;
}

.glyphicon {
	font-family: 'FontAwesome';
}

li > a.connection-status {
	padding-top: 3px;
	padding-bottom: 2px;
}

body > div.container {
	margin-top: auto;
	min-height: 450px;
}

#footer{
	position: relative;
	bottom: 0px;
}

.navbar {
	background:#e8eaf6;
	border-bottom: 2px solid;
	border-color:  #428bca;
    margin-bottom: 5px;
}

.navbar-brand {
	padding: 3px 15px;
}

.no-cursor{
       cursor:default;
   }

 li.no-background > a:hover, li.no-background > a:focus{
       background-color:transparent;
   }

 li.no-background-logout > a:hover, li.no-background > a:focus{
        background-color:#c5cae9;
        //margin-top: 1px;
        border-radius: 5px 5px 0 0;
        border-bottom:1px solid transparent;
    }

.nav > li > a:hover, .nav > li > a:focus {
    background-color:#c5cae9;
}

table.table-blue > tbody > tr.info > td{
    background-color: rgb(238, 238, 255);
}

.link-logout{
    border-top-width: 1px;
    margin-top: 1px;
    margin-right: 2px;
    }

.logout{
    margin-top:2px;
    font-weight: bold;
}

.dashboard-title {
    margin-top: 0px;
}

.dashboard {
  overflow: hidden;
}

.table input.ng-invalid.ng-dirty {
    border-color: #a94442;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
  }

.progress{
	margin:0;
  position: relative;
}

.progress span {
    position: absolute;
    display: block;
    width: 100%;
    color: black;
    white-space:nowrap;
}

.error{
	color: #a94442;
    background-color: #f2dede;
}

.question{
	color: #428bca;
}

.td-primary{
	color: #ffffff;
	background-color: #428bca !important;
	border: 1px solid #dddddd;
	border-bottom-color: transparent;
	cursor: default;
	font-weight: bold;
}

ul.dropdown-menu-form {
    padding: 5px 30px 0;
    max-height: 300px;
    overflow-y: scroll;
}

/*replace the content value with the
corresponding value from the list below*/
.glyphicon {
    position: relative;
}

.glyphicon-chevron-right:before {
    content: "\f054";
    font-family: FontAwesome;
}

.glyphicon-chevron-left:before {
    content: "\f053";
    font-family: FontAwesome;
}

.glyphicon-arrow-right:before {
    content: "\f054";
    font-family: FontAwesome;
}

.glyphicon-arrow-left:before {
    content: "\f053";
    font-family: FontAwesome;
}

.glyphicon-chevron-up:before {
    content: "\f077";
    font-family: FontAwesome;
}

.glyphicon-chevron-down:before {
    content: "\f078";
    font-family: FontAwesome;
}

.navbar-form.navbar-right:last-child {
	margin-right: 0px;
}

.nav-tabs > li > a {
    margin-top: 2px;
    border-radius: 6px 6px 0 0;
    font-weight: bold;
}

.nav-tabs > li.menuActive > a, .nav-tabs > li.menuActive > a:hover, .nav-tabs > li.menuActive > a:focus {
	color: #ffffff;
	background-color: #428bca;
	border: 1px solid #dddddd;
	border-bottom-color: transparent;
	cursor: pointer;
}

.panelHeadingTree > .panel-heading{
	box-sizing: border-box;
	padding: 6px 12px;
}

.panelHeadingTree > .panel-heading:hover{
	background-color: #e6e6e6;
	cursor: pointer;
}

.panel .panel-heading h2.panel-title {
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.widgets {
    padding-bottom: 5px;
}

.widget.panel {
  margin-bottom: 0px;
  height: 100%;

  &:hover {
    border-color: #adadad;
  }

  & .panel-body {
    padding: 5px;
  }
}

.edit .column {
    padding-top: 0px;
}

a.accordion-toggle:hover{
	text-decoration: none !important;
}

.accordion-active.panel {
	border: 1px solid #dddddd;
}

.accordion-active .panel-heading{
	color: #ffffff;
	background-color: #428bca;
	border-color: #428bca;
}

.table-title{
	color: #ffffff;
	background-color: #428bca;
	border-color: #428bca;
}

.accordion-button{
	color: #333333;
	background-color: #f5f5f5;
	border-color: #dddddd;
	margin-top: 5px;
	margin-botton: 0px;

	border-top-right-radius: 3px;
	border-top-left-radius: 3px;

	//font-weight: 500;
	line-height: 1.1;
	font-size: 13px;

	width:100%;
	text-align:left;
}

.accordion-button-active{
	color: #ffffff !important;
	background-color: #428bca !important;
	border-color: #428bca !important;
}

.tree {
    min-height:20px;
    padding:19px;
    margin-bottom:20px;
    background-color:#fbfbfb;
    border:1px solid #999;
    -webkit-border-radius:4px;
    -moz-border-radius:4px;
    border-radius:4px;
    -webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow:inset 0 1px 1px rgba(0, 0, 0, 0.05);



    -webkit-touch-callout: none !important;
	-webkit-username-select: none !important;
	-khtml-username-select: none !important;
	-moz-username-select: none !important;
	-ms-username-select: none !important;
}
.tree ul{
	padding-left:0px

}
.tree ul ul{
	padding-left:35px
}
.tree li li{
	padding-top:15px
}
.tree li {
    list-style-type:none;
    margin:0;
    padding:10px 5px 0 5px;
    position:relative;
}
.tree li::before, .tree li::after {
    content:'';
    left:-20px;
    position:absolute;
    right:auto
}
.tree li::before {
    border-left:1px solid #999;
    bottom:50px;
    height:100%;
    top:0;
    width:1px
}
.tree li::after {
    border-top:1px solid #999;
    height:20px;
    top:30px;
    width:25px
}
.tree li span {
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    border:1px solid #999;
    border-radius:5px;
    display:inline-block;
    padding:3px 8px;
    text-decoration:none
}
.tree li button {
    padding:3px 8px;
}
.tree li.parent_li>span {
    cursor:pointer
}
.tree>ul>li::before, .tree>ul>li::after {
    border:0
}
.tree li:last-child::before {
    height:30px
}
.tree li.parent_li>span:hover, .tree li.parent_li>span:hover+ul li span {
    background:#eee;
    border:1px solid #94a0b4;
    color:#000
}

.container-full {
  margin: 0 auto;
  //width: 100%;
}

#wrapper {
  padding-left: 300px;
  transition: all 0.4s ease 0s;
}

#sidebar-wrapper {
  margin-left: -300px;
  left: 315px;
  width: 300px;
  background: #FFFFFF;
/*   position: fixed; */
  position: absolute;
  height: auto;
  overflow-y: auto;
  z-index: 1000;
  transition: all 0.4s ease 0s;
}

.colorpicker-visible,
.colorpicker-visible .dropdown-menu {
  z-index: 10000;
}

.color-box {
  border-radius: 3px;
  width: 30px;
  height: 22px;
}

div.widget-content {
  height: 100%;
  overflow: hidden;

  & > div {
    height: 100%;
  }
}

.configDashboard{
	width :120px;
	height:120px;
    border: 1px solid;
    border-color: #428bca;
	padding: 2px 0px;
	cursor: pointer;
	margin-bottom: 10px;
}
.configDashboard.active {
	background: #EEEEEE;
}
.configDashboardRow{
	padding: 2px 4px;
}
.configDashboardWidget{
	right: 0px;
	border: 1px solid;
    border-color: #428bca;
    border-top-width: 5px;
}

.form-group .form-control {
  padding: 6px 3px;
}

.form-group input.form-control {
  padding: 6px 7px;
}

.form-group label.form-group-label {
  display: block;
  margin: 0;
  padding-left: 3px;
}

.form-group-sep {
  display: block;
  margin: 5px 0 0 0;
}

.panel .list-group-item {
  padding: 10px;
}

.modal.fade {
  overflow-y: scroll;
}

.tooltip{
	width:200px
}

button.btn.disabled {
    pointer-events: auto;
}

i.text-danger + div.tooltip .tooltip-inner{
	color: #A94442;
	background-color: #F2DEDE;
}

i.text-danger + div.tooltip.left .tooltip-arrow {
	border-left-color: #A94442;
}

i.text-danger + div.tooltip.right .tooltip-arrow {
    border-right-color: #A94442;
 }

.panel-widget-config{
	margin-bottom: 10px
}
.full-height {
	height: 100%;
}
#multiselctButton > button {
	min-height :29px !important;
	height: 29px !important;
	width:100%;
	text-align: right;
}
#multiselctButton > button > div:nth-child(1){
	padding: 0px 0px;
}

.about-panel{
	margin: 50px 0px;
}

.rowAnimation{
	transition: all 200ms;
  	-webkit-transition: all 200ms ease-in-out;
}

.form-horizontal .form-inline .form-group {
	margin-left: 0;
	margin-right: 0;
}

.multiSelect > button.ng-binding {
	min-height: 29px !important;
	font-size: inherit;
	width: 100%;

}

.form-control.sp-replacer {
	width: 100%;
}

.form-control .sp-preview {
	width: 100%;
	margin-top: -2px;
	border-radius: 4px;
	border: none 1px;
}

.form-control .sp-preview-inner {
	border-radius: 3px;
}

.alert-danger-report{
  margin-left: 5px;
  height:28px;
  text-align: center;
  vertical-align: middle;
  line-height: 28px;
  padding: 0px !important;
  width:auto;

}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 1px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}

.table-bordered tr>th.energy-table-header-color {
  background-color: rgba(66, 139, 202, 0.05);
  border-width: 0px;
}

.table-bordered tr>th.energy-table-header-no-color {
  background-color: transparent;
  border-width: 0px;
}

.table-bordered tr>th.energy-table-header-row {
  border-width: 0px;
}
.table-bordered tr>td.energy-table-row {
  background-color: rgba(66, 139, 202, 0.05);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-clear-button {
     display: none;
     width: 0px;
     overflow: hidden;
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.dashboard-button {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
  background: transparent;
  border: none !important;
}
.dashboard-button-disabled {
  cursor:not-allowed !important;
}

.file-browser {
  font-size: 13px;
}

.file-browser .breadcumbs {
  background-color: #f5f5f5;
  padding: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  border: 1px solid #dddddd;
}

.file-browser .file-icon {
  font-size: 15px;
  margin-right: 3px;
}

table.file-browser tbody tr:hover {
  background-color: #e5e5e5;
}

table.file-browser tr>th,
table.file-browser tr>td {
  vertical-align: middle;
}

table.file-browser a {
  width: 100%;
  display: block;
  color: #333333;
}

table.file-browser a:link,
table.file-browser a:active,
table.file-browser a:hover,
table.file-browser a:visited {
  text-decoration: none;
}

@media (max-width: 767px){
.navbar-right {
    float: right !important;
    margin-right: -3px;
    }
}

@media (max-width: 767px){
.navbar-nav > li {
    float: left;
    }
}

.caret-right{
      position:relative;
      float:right;
      top:6px;
      margin-right: 5px;
    }

.left{
    text-align:left;
    padding-left: 5px !important;
}

.multiselect-container{
    border-radius:5px
}

.picture{
    max-height:167px;
}

.center-table{
    text-align:center;
}

.uneditable-input {
  background:inherit;
  border:none;
}

.active-lang{
    background-color:#428bca !important;
    color:#ffffff !important;
}

.language-change{
    font-weight: bold;
}
.dropdown-menu > li.language-change > a {
    padding-left:5px;
}

li.language-change > a:hover, li.language-change > a:focus{
    background-color:#dddddd;
}

.my-menu{
    float:left;
    margin-top:5px;
    border: 0;
    background-color: transparent;
    font-weight: bold;
    color:#337ab7;
}

.menu-right{
    right:auto !important;
    left:auto !important;
    margin-top:0px;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
}

button.lang-choice:hover, button.lang-choice:focus, button.lang-choice:active {
    color: #337ab7 !important;
    border: 0 !important;
    background-color: transparent !important;
    outline:none !important;
    outline-color: transparent !important;
    -webkit-box-shadow:none;box-shadow:none;
}

.open > .dropdown-toggle.lang-choice {
    background-color: #428bca !important;
    border-bottom-left-radius:0px;
    border-bottom-right-radius:0px;
    color: white !important;
    outline:none !important;
    outline-color: transparent !important;
    -webkit-box-shadow:none;box-shadow:none;
    padding-bottom: 8px;
    padding-top:8px;
    margin-top:3px;
}

.width-max{
    width:100% !important;
    table-layout: fixed;
}
.width-max th {
    word-break: break-word;
}

.datetimepicker .table th {
    width: auto;
}

.multiSelect > Button {
    min-height:29px !important;
    font-size:11px !important;
}

.multiSelect .checkboxLayer{
    min-width:320px;
}


#cronHours .checkboxLayer{
    min-width:100px;
    width:150px;
}
.multiSelect .multiSelectItem {
    min-width:100px;
}

.form-inline .form-group{
    display: inline-block;
    margin-bottom:0;
}

@media (max-width: 991px){
    #output-width{
        width:50% !important;
    }
}
table.table-vcenter > tbody > tr > td, table.table-vcenter > thead > tr > th {
  vertical-align: middle;
}

@media (min-width: 768px){
    .height-max{
        height: 100%;
    }
}

.modal-footer,.modal-header{
    padding: 5px 10px;
}

.modal-header .close {
    margin-top:0;
}

@media (min-width: 992px){
    .modal-lg {
        width: 650px;
    }
}

@media (min-width: 768px){
    .form-width{
        width: 100% !important;
    }
}

@media (min-width: 402px) and (max-width: 767px) {
    .diff-width{
        width: 16.67%;
    }
}

.multiSelect .multiSelectItem:hover,
.multiSelect .multiSelectGroup:hover {
    background-image: none !important;
    background-color: #337ab7 !important;
    color: white !important;
    cursor: pointer;
    border: 0px solid #ccc !important;
}

.multiSelect .multiSelectItem:not(.multiSelectGroup).selected {
    background-image: none;
    background-color:#EDF2F8;
    color: black;
    cursor: pointer;
    border: none;
    font-weight: bold;
}
.multiSelect .multiSelectItem{
    padding: 0px;
    min-height: 24px;
}
.multiSelect > button,.multiSelect > button:hover {
    background-image: none;
}

.multiSelect .tickMark {
    color:inherit;
    font-weight: bold;
}

.multiSelect > button:focus, .multiSelect > button:active {
    border-color: #66afe9 !important;
    outline: 0 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}
.multiSelect .acol{
    line-height:2;
}

li.transparent > a:hover, li.transparent > a:focus {
     background-color: transparent;
}

@media (min-width: 768px){
    .form-inline .input-group .input-group-btn {
        width: 1%;
    }
}
.td_button{
    color: #ffffff;
    background-color: #337ab7;
    border-color: #2e6da4;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
}
.bold{
    font-weight: bold;
    background-color: rgba(66, 139, 202, 0.07);
}

.heatmap-label-value{
    font-weight: bold;
}

.report-table > thead > tr> th{
    border-width: 0px;
}

/*css swicth*/
.can-toggle {
    position: relative;
}

.can-toggle *, .can-toggle *:before, .can-toggle *:after {
    box-sizing: border-box;
}

.can-toggle input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.can-toggle input[type="checkbox"][disabled] ~ label {
    pointer-events: none;
}

.can-toggle input[type="checkbox"][disabled] ~ label .can-toggle__switch {
    opacity: 0.4;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:before {
    content: attr(data-unchecked);
    left: 0;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    content: attr(data-checked);
}

.can-toggle label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
}


.can-toggle label .can-toggle__switch {
    position: relative;
}

.can-toggle label .can-toggle__switch:before {
    content: attr(data-checked);
    position: absolute;
    top: 0;
    text-transform: uppercase;
    text-align: center;
}

.can-toggle label .can-toggle__switch:after {
    content: attr(data-unchecked);
    position: absolute;
    z-index: 5;
    text-transform: uppercase;
    text-align: center;
    transform: translate3d(0, 0, 0);
}

.can-toggle input[type="checkbox"][disabled] ~ label {
    color: rgba(119, 119, 119, 0.5);
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch {
    background-color: #ccc;
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    color: white;
}

.can-toggle input[type="checkbox"]:hover ~ label {
    color: white;
}

.can-toggle input[type="checkbox"]:checked ~ label:hover {
    color: white;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch {
    background-color: #ccc;
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    color: white;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch {
    background-color: #ccc;
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    color: white;
}

.can-toggle label .can-toggle__label-text {
    flex: 1;
}

.can-toggle label .can-toggle__switch {
    transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
    background-color: #ccc;
}

.can-toggle label .can-toggle__switch:before {
    color: rgba(255, 255, 255, 0.5);
}

.can-toggle label .can-toggle__switch:after {
    -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
    color: white;
}

.can-toggle input[type="checkbox"]:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle input[type="checkbox"]:checked ~ label .can-toggle__switch:after {
    transform: translate3d(65px, 0, 0);
}

.can-toggle input[type="checkbox"]:checked:focus ~ label .can-toggle__switch:after, .can-toggle input[type="checkbox"]:checked:hover ~ label .can-toggle__switch:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.can-toggle label {
    font-size: 14px;
}

.can-toggle label .can-toggle__switch {
    height: 26px;
    flex: 0 0 134px;
    border-radius: 5px;
}

.can-toggle label .can-toggle__switch:before {
    left: 67px;
    font-size: 8px;
    line-height: 26px;
    width: 67px;
    padding: 0 12px;
}

.can-toggle label .can-toggle__switch:after {
    top: -4px;
    left: 2px;
    border-radius: 5px;
    width: 65px;
    line-height: 32px;
    font-size: 12px;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.can-toggle label .can-toggle__switch:hover:after {
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}
/*css swicth - koniec*/

 @keyframes fa-blink {
     0% { opacity: 0; }
     25% { opacity: 0.5; }
     50% { opacity: 1; }
     75% { opacity: 0.5; }
     100% { opacity: 0; }
 }
.fa-blink {
   -webkit-animation: fa-blink 2s linear infinite;
   -moz-animation: fa-blink 2s linear infinite;
   -ms-animation: fa-blink 2s linear infinite;
   -o-animation: fa-blink 2s linear infinite;
   animation: fa-blink 2s linear infinite;
}

.btn-justborder {
    background-color: #fff;
    border-width: 3px;
    color: #000;
}

.fc-body .fc-row {
    min-height: 45px;
}

.fc-sat {     background-color: #00000002;  }
.fc-sun {     background-color: #00000004; }

.tick line {
    stroke: lightgrey;
    opacity: 0.5;
}

.line {
  fill: none;
  stroke: #444;
  stroke-width: 2px;
}

.ui-select-search {
    width:130px !important;
    font-family: FontAwesome, Arial;
}

.ui-select-toggle {
    width:130px !important;
}

.ui-select-choices {
    width:200px !important;
}

.ui-select-choices-descr{
    padding-left: 1em;
    font-size: 90%;
}
