@import "/jslib/bootstrap/less/variables.less";

.config-tree {

  margin-bottom: 5px;

  .panel-heading {
    border-bottom-width: 0;
  }

  &.opened .panel-heading {
    border-bottom-width: 1px;
  }

  &.active .panel-heading {
    background-color: @component-active-bg;
    color: @component-active-color;
  }

  .panel-collapse > .panel-body.list-group {
    padding: 0;

    & > .list-group-item:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .toggle.fa {
    cursor: pointer;
  }

  .config-tree-label {
    font-size: 12px;
    vertical-align: middle;
    line-height: 20px;
    min-height: 20px;
    display: table;
    left: 0px;
    right: 0px;

    .item-label {
      display: inline-block;
      line-height: 20px;
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 190px;
    }
  }

    .config-tree-cell{
        display: table-cell;
    }
    .config-tree-row{
        display:table-row;
    }
    .config-tree-cell-icon{
        width:20px;
    }

  .label {
    border-radius: 1em;
    padding: 0.3em 0.8em;
    cursor: default;
  }

  .label-status {
    cursor: pointer;
    padding: 0.5em;
    display: inline-block;
  }
}

.config-form {

  .panel {
    margin: 0 -10px 0 -10px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 0;
  }

  .panel-body.main {
    padding-bottom: 0;
    min-height: 300px;

    .config-form-options {
      height: 280px;
      line-height: 280px;
      vertical-align: middle;
      text-align: center;

      button {
        width: 180px;
      }
    }
  }

  .thumbnail {
    border-width: 2px;

    .thumbnail-price {
      position: absolute;
      right:  10px;
      font-size: 12px;
      font-weight: bold;
      color: @label-color;
      background-color: @label-warning-bg;
      padding: 3px 5px;
      border-radius: 10px;
    }

    .caption {
      text-align: center;

      span.caption-label {
        font-weight: normal;
        display: inline-block;
        width: 100%;
        overflow: hidden;
        line-height: 1.5em;
        height: 3em;
        &.selected {
          font-weight: bold;
        }
      }
    }

    &.active {
      border-color: @component-active-bg;

      span.caption-label {
        font-weight: bold;
      }
    }
  }

  .units {
    button.dropdown-toggle {
      width: 55px;
      text-align: right;
    }

    .dropdown-menu {
      min-width: 60px;
    }
  }

  .multiselect {
    text-align: left;
    width: 100%;
    position: relative;
    padding-left: 8px;
    color: inherit;
    background-color: inherit;

    .caret {
      position: absolute;
      top: 12px;
      right: 5px;
    }
  }

  .multiselect-container {
    left: 3px;

    &.dropdown-menu > .active > a {
      background-color: inherit;
      color: inherit;
    }

    label {
      color: inherit !important;
    }
  }

  .config-rule {
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid;
    border-color: inherit;
  
    .form-group {
      vertical-align: top;
      width: 100%;

      .form-control {
        width: 100%;
      }
    }

    .config-rule-actions {
      .form-control, .btn {
        margin-bottom: 3px;
        vertical-align: top;
      }

      & > div {
        display: inline-block;
      }
    }
  }
}

ul.error-list {
  padding-left: 0;
  list-style: none;
  min-width: 200px;
  margin: 0;
}

#status-tooltip {
  .arrow {
    top: 42px;
  }
}

.menu-item{
    padding: 3px !important;
    border-left-width: 1px !important;
    border-bottom-width: 1px !important;
    border-bottom-color: rgb(221, 221, 221) !important;
    border-bottom-style: solid !important;
    cursor: pointer;
}
.checkBoxContainer{
    max-height: 300px;
    overflow-y: auto !important;
}





div.autoscroll {
    overflow: visible;
    overflow-y: auto;
    overflow-x: hidden;
}

div.autoscroll:hover {
    /* overflow-y: scroll; */
    overflow-y: auto;
}